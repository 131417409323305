import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
//import data from "../../../assets/data/physical_list";
import { Edit, Trash2 } from "react-feather";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";

const Board_list = () => {

	const [products, setProducts] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("All");
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [productCount, setProductCount] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		fetchProducts();
	}, []);

	const fetchProducts = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_API_URL + 'blogs/blogsList');
			setProducts(response.data.blogs);
		} catch (error) {
			console.error('Error fetching products:', error);
		}
	};

	const deleteProduct = (productId) => {
		axios.delete(process.env.REACT_APP_API_URL + `blogs/delete/${productId}`)
			.then((response) => {
				toast.success('Product deleted successfully');
				// Remove the deleted product from the state
				setProducts(products.filter((product) => product._id !== productId));
			})
			.catch((error) => {
				console.error('Error deleting product:', error);
			});
	};

	const formatDate = (isoDate) => {
		return new Intl.DateTimeFormat("en-US", {
		  year: "numeric",
		  month: "long",
		  day: "numeric",
		}).format(new Date(isoDate));
	  };
	  

	return (
		<Fragment>
			<ToastContainer />
			<Breadcrumb title="Blogs List" parent="Physical" />
			<Container fluid={true}>
				{/* <div>
        <label>Filter by Category:</label>
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="All">All Categories</option>
          <option value="HolyBible">Holy Bible</option>
          <option value="WeddingBible">Wedding Bible</option>
		  <option value="Board">Board</option>
        </select>
      </div>
	  <div>
        <label>Search by Product Name:</label>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div> */}
				<Row className="products-admin ratio_asos">
					<p>{productCount} products</p>
					{products && products.length > 0 ? (
						products.map((myData, i) => (
							<Col xl="3" sm="6" key={i}>
								<Card>
									<div className="products-admin">

										<CardBody className="product-box">
											<div className="img-wrapper">

												<div className="lable-block">
													{myData.tag === "new" ? (
														<span className="lable3">{myData.tag}</span>
													) : (
														""
													)}
													{myData.discount === "on sale" ? (
														<span className="lable4">{myData.offerPercentage}</span>
													) : (
														""
													)}
												</div>
												<div className="front">
													<a href="/#" className="bg-size">
														<img
															alt=""
															className="img-fluid blur-up bg-img lazyloaded"
															src={myData.image[0]}
														/>
													</a>
													<div className="product-hover">
														<ul>
															<li>
																<Link to={`/infoston/edit-blog/${myData._id}`}>
																	<Button color="btn" type="button">
																		<Edit className="editBtn" />
																	</Button>
																</Link>

															</li>
															<li>
																<Button color="btn" type="button">
																	<Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
																</Button>
															</li>
														</ul>
													</div>
												</div>
											</div>
											<div className="product-detail">
												<a href="/#">
													{" "}
													<h6 style={{color:'#000', fontSize:'20px'}}>{myData.title}</h6>
													<p>Published by {myData.author}</p>
													<p>Published Date {formatDate(myData.publishedDate)}</p>
												</a>
											</div>
										</CardBody>
									</div>
								</Card>
							</Col>
						))
					) : (
						<p className="text-center w-100 text-gray-600">No blogs available</p>
					)}
				</Row>
			</Container>
		</Fragment>
	);
};

export default Board_list;
