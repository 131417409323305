import React, { Fragment, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Button,
    FormFeedback
} from "reactstrap";
import MDEditor from "@uiw/react-md-editor";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Blogs = () => {

    const [data, setData] = useState({
        bannerImage: [],
        title: "",
        slug: "",
        topic: "",
        author: "",
        image:[],
        publishedDate: "",
        content: ""
    });
    const [uniqueId, setUniqueId] = useState(Date.now());
    const [imageUniqueId, setImageUniqueId] = useState(Date.now());


    const [file, setFile] = useState([]);
    const [imageFile, setImageFile] = useState([]);

    const {
        handleSubmit,
        control,
        getValues,
        formState: { errors },
        reset,
        watch,
        register
    } = useForm({
        defaultValues: data,
    });

    const keywords = watch('keywords') || [];

    const onAddKeyword = () => {
        const newIndex = keywords.length;
        register(`keywords[${newIndex}]`);
    };

    const fileChange = (e) => {
        setFile([...file, ...e.target.files]);
    };

    const fileImageChange = (e) => {
        setImageFile([...imageFile, ...e.target.files]);
    };

    const clearFileState = () => {
        setFile([]);
        setImageFile([]);
    };

    const handleValidSubmit = async (data) => {
        console.log(data, "data");
        try {
            const formData = new FormData();
			const values = getValues();

			// for (const [key, value] of Object.entries(values)) {
			// 	formData.append(key, value);
			// }

			for (var j = 0; j < file.length; j++) {
				formData.append("bannerImage", file[j]);
			}
            for (var i = 0; i < file.length; i++) {
				formData.append("image", imageFile[i]);
			}

            formData.append("title", data.title);
            formData.append("slug", data.slug);
            formData.append("topic", data.topic);
            formData.append("author",data.author);
            formData.append("publishedDate", data.publishedDate);
            formData.append("content",data.content);

			

            const response = await axios.post(process.env.REACT_APP_API_URL + 'blogs/create',
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                    },
                }
            );

            if (response.data.success) {
                console.log(response.data, "board")
                toast.success("Blog Created successfully");
                reset();
                clearFileState();
            } else {
                toast.error("An error occurred while creating the product");
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                const { data } = error.response;
                if (data && data.message) {
                    if (Array.isArray(data.message)) {
                        toast.error(data.message[0]);
                    } else {
                        toast.error(data.message);
                    }
                } else {
                    toast.error("An error occurred while processing the request");
                }
            } else if (error.request) {
                toast.error("No response received from the server");
            } else {
                toast.error("An error occurred while sending the request");
            }
        }
    };

    const CustomMDEditor = ({ name, control }) => {
        return (
            <Controller
                name={name}
                control={control}
                defaultValue={data.description}
                render={({ field }) => (
                    <MDEditor value={field.value} onChange={field.onChange} />
                )}
            />
        );
    };

    function deleteFile(e) {
        const s = file.filter((item, index) => index !== e);
        setFile(s);
        setUniqueId(Date.now());
    }

    function deleteImageFile(e) {
        const s = imageFile.filter((item, index) => index !== e);
        setImageFile(s);
        setImageUniqueId(Date.now());
    }


    return (
        <Fragment>
            <Breadcrumb title="Add Blogs" parent="Physical" />
            <ToastContainer />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Add Blogs</h5>
                            </CardHeader>
                            <CardBody>
                                <Row className="product-adding">
                                    <Col xl="5">
                                        <div className="add-product">
                                            <h3>Select Blog Banner Image</h3>
                                            <Col xl="3">
                                                <div className="add-product">

                                                    {file && file.length < 6 && (
                                                        <Row>
                                                            <Col xl="12 xl-50" sm="6 col-3">
                                                                <Input
                                                                    key={uniqueId}
                                                                    className="upload"
                                                                    type="file"
                                                                    name="bannerImage"
                                                                    accept="image/png, image/gif,image/webp, image/jpeg"
                                                                    onChange={fileChange}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    <Row style={{ marginTop: "15px" }}>
                                                        {file &&
                                                            file.length > 0 &&
                                                            file.map((item, index) => {
                                                                return (
                                                                    <Col xl="6" sm="3 col-3">
                                                                        <div key={index}>
                                                                            <img
                                                                                style={{ width: "100%" }}
                                                                                src={URL.createObjectURL(item)}
                                                                                alt=""
                                                                            />
                                                                            <Button
                                                                                style={{ marginTop: "10px" }}
                                                                                type="button"
                                                                                color="secondary"
                                                                                size="sm"
                                                                                onClick={() => deleteFile(index)}
                                                                            >
                                                                                delete
                                                                            </Button>
                                                                        </div>
                                                                    </Col>
                                                                );
                                                            })}
                                                    </Row>
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                    <Col xl="5">
                                        <div className="add-product">
                                            <h3>Select Blog Image</h3>
                                            <Col xl="3">
                                                <div className="add-product">

                                                    {imageFile && imageFile.length < 6 && (
                                                        <Row>
                                                            <Col xl="12 xl-50" sm="6 col-3">
                                                                <Input
                                                                    key={imageUniqueId}
                                                                    className="upload"
                                                                    type="file"
                                                                    name="image"
                                                                    accept="image/png, image/gif,image/webp, image/jpeg"
                                                                    onChange={fileImageChange}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    <Row style={{ marginTop: "15px" }}>
                                                        {imageFile &&
                                                            imageFile.length > 0 &&
                                                            imageFile.map((item, index) => {
                                                                return (
                                                                    <Col xl="6" sm="3 col-3">
                                                                        <div key={index}>
                                                                            <img
                                                                                style={{ width: "100%" }}
                                                                                src={URL.createObjectURL(item)}
                                                                                alt=""
                                                                            />
                                                                            <Button
                                                                                style={{ marginTop: "10px" }}
                                                                                type="button"
                                                                                color="secondary"
                                                                                size="sm"
                                                                                onClick={() => deleteImageFile(index)}
                                                                            >
                                                                                delete
                                                                            </Button>
                                                                        </div>
                                                                    </Col>
                                                                );
                                                            })}
                                                    </Row>
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                    <Col xl="7">
                                        <Form
                                            className="needs-validation add-product-form"
                                            onSubmit={handleSubmit(handleValidSubmit)}
                                        >
                                            <div className="form form-label-center">
                                                <FormGroup className="form-group mb-3 row">
                                                    <Label className="col-xl-3 col-sm-4 mb-0">
                                                        Blog Title:
                                                    </Label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Controller
                                                            name="title"
                                                            control={control}
                                                            defaultValue={data.title}
                                                            render={({ field }) => (
                                                                <>
                                                                    <Input
                                                                        className="form-control"
                                                                        {...field}
                                                                        type="text"
                                                                        invalid={!!errors.title}
                                                                    />
                                                                    {errors.name && (
                                                                        <FormFeedback>{errors.title.message}</FormFeedback>
                                                                    )}
                                                                </>
                                                            )}
                                                            rules={{ required: "Blog Title is required" }}
                                                        />
                                                    </div>
                                                </FormGroup>

                                                <FormGroup className="form-group mb-3 row">
                                                    <Label className="col-xl-3 col-sm-4 mb-0">
                                                        URL:
                                                    </Label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Controller
                                                            name="slug"
                                                            control={control}
                                                            defaultValue={data.slug}
                                                            render={({ field }) => (
                                                                <>
                                                                    <Input
                                                                        className="form-control"
                                                                        {...field}
                                                                        type="text"
                                                                        invalid={!!errors.slug}
                                                                    />
                                                                    {errors.name && (
                                                                        <FormFeedback>{errors.slug.message}</FormFeedback>
                                                                    )}
                                                                </>
                                                            )}
                                                            rules={{ required: "Slug is required" }}
                                                        />
                                                    </div>
                                                </FormGroup>

                                                <FormGroup className="form-group mb-3 row">
                                                    <Label className="col-xl-3 col-sm-4 mb-0">
                                                        topic:
                                                    </Label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Controller
                                                            name="topic"
                                                            control={control}
                                                            defaultValue={data.topic}
                                                            render={({ field }) => (
                                                                <>
                                                                    <Input
                                                                        className="form-control"
                                                                        {...field}
                                                                        type="text"
                                                                        invalid={!!errors.topic}
                                                                    />
                                                                    {errors.topic && (
                                                                        <FormFeedback>{errors.topic.message}</FormFeedback>
                                                                    )}
                                                                </>
                                                            )}
                                                            rules={{ required: "topic is required" }}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </div>

                                            <div className="form">
                                                <FormGroup className="form-group mb-3 row">
                                                    <Label className="col-xl-3 col-sm-4 mb-0">
                                                        author:
                                                    </Label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Controller
                                                            name="author"
                                                            control={control}
                                                            defaultValue={data.author}
                                                            render={({ field }) => (
                                                                <>
                                                                    <Input
                                                                        className="form-control"
                                                                        {...field}
                                                                        type="text"
                                                                        invalid={!!errors.author}
                                                                    />
                                                                    {errors.author && (
                                                                        <FormFeedback>{errors.author.message}</FormFeedback>
                                                                    )}
                                                                </>
                                                            )}
                                                            rules={{ required: "author is required" }}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                {/* <FormGroup className="form-group mb-3 row">
                                                    <Label className="col-xl-3 col-sm-4 mb-0">Keywords:</Label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Controller
                                                            name="keywords"
                                                            control={control}
                                                            defaultValue={[]}
                                                            render={({ field }) => (
                                                                <>
                                                                    {keywords.map((keywordField, index) => (
                                                                        <div key={index} className="mb-2">
                                                                            <Input
                                                                                className="form-control"
                                                                                {...keywordField}
                                                                                type="text"
                                                                                placeholder={`Keyword ${index + 1}`}
                                                                            />
                                                                            <Button
                                                                                type="button"
                                                                                onClick={() => field.remove(index)}
                                                                            >
                                                                                Remove
                                                                            </Button>
                                                                        </div>
                                                                    ))}
                                                                    <Button
                                                                        type="button"
                                                                        onClick={() => field.append('')}
                                                                    >
                                                                        Add Keyword
                                                                    </Button>
                                                                    {errors.keywords && (
                                                                        <FormFeedback>{errors.keywords.message}</FormFeedback>
                                                                    )}
                                                                </>
                                                            )}
                                                            rules={{
                                                                validate: value => {
                                                                    if (value.length === 0 || value.includes('')) {
                                                                        return 'Please provide valid keywords';
                                                                    }
                                                                    return true;
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                </FormGroup> */}

                                                <FormGroup className="form-group mb-3 row">
                                                    <Label className="col-xl-3 col-sm-4 mb-0">Published Date:</Label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Controller
                                                            name="publishedDate"
                                                            control={control}
                                                            defaultValue={data.publishedDate || new Date()}  // Set to today's date if not already set
                                                            render={({ field }) => (
                                                                <>
                                                                    <DatePicker
                                                                        className="form-control"
                                                                        {...field}
                                                                        selected={field.value}
                                                                        onChange={(date) => {
                                                                            field.onChange(date);
                                                                        }}
                                                                        dateFormat="dd-MM-yyyy"
                                                                        isClearable
                                                                        showYearDropdown
                                                                        placeholderText="Select Date"
                                                                    />
                                                                    {errors.publishedDate && (
                                                                        <FormFeedback>{errors.publishedDate.message}</FormFeedback>
                                                                    )}
                                                                </>
                                                            )}
                                                            rules={{ required: "Submitted Date is required" }}
                                                        />
                                                    </div>
                                                </FormGroup>



                                                <FormGroup className="form-group mb-3 row">
                                                    <Label className="col-xl-3 col-sm-4">
                                                        Add Content
                                                    </Label>
                                                    <div className="col-xl-8 col-sm-7 description-sm">
                                                        <CustomMDEditor name="content" control={control} />
                                                    </div>
                                                </FormGroup>
                                            </div>
                                            <div className="offset-xl-3 offset-sm-4">
                                                <Button type="submit" color="primary">
                                                    Add
                                                </Button>
                                                <Button type="button" color="light">
                                                    Discard
                                                </Button>
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default Blogs;
