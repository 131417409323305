'use client'
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import MDEditor from "@uiw/react-md-editor";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  FormFeedback,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditBlog = ({ params }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [contentImage, setContentImage] = useState(null);
  const [previewBanner, setPreviewBanner] = useState(null);
  const [previewContent, setPreviewContent] = useState(null);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  // Fetch Blog Data
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `blogs/blog/${id}`);
        const blog = response.data.blog;
        setValue("title", blog.title);
        setValue("slug", blog.slug);
        setValue("topic", blog.topic);
        setValue("author", blog.author);
        setValue("content", blog.content);
        setValue("publishedDate", new Date(blog.publishedDate));
        setBannerImage(blog.bannerImage);
        setContentImage(blog.image);
        setPreviewBanner(blog.bannerImage);
        setPreviewContent(blog.image);
      } catch (err) {
        setError("Failed to fetch blog details.");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchBlog();
    }
  }, [id, setValue]);

  const handleBannerChange = (e) => {
    const file = e.target.files[0];
    setBannerImage(file);
    setPreviewBanner(URL.createObjectURL(file));
  };

  const handleContentImageChange = (e) => {
    const file = e.target.files[0];
    setContentImage(file);
    setPreviewContent(URL.createObjectURL(file));
  };


  // Handle Form Submission
  // Handle Form Submission
  const handleUpdate = async (data) => {
    try {
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("slug", data.slug);
      formData.append("topic", data.topic);
      formData.append("author", data.author);
      formData.append("publishedDate", data.publishedDate);
      formData.append("content", data.content);

      if (bannerImage && typeof bannerImage !== "string") {
        formData.append("bannerImage", bannerImage);
      }

      if (contentImage && typeof contentImage !== "string") {
        formData.append("image", contentImage);
      }

      await axios.put(`${process.env.REACT_APP_API_URL}blogs/edit/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      alert("Blog updated successfully!");
      navigate("/infoston/blogs-list");
    } catch (error) {
      alert("Error updating blog. Try again.");
    }
  };

  return (
    <Container>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-600">{error}</p>
      ) : (
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Edit Blog</h5>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(handleUpdate)}>

                  {/* Banner Image */}
                  <FormGroup>
                    <Label>Banner Image</Label>
                    <Input type="file" accept="image/*" onChange={handleBannerChange} />
                    {previewBanner && (
                      <div style={{ marginTop: "12px" }}>
                        <img
                          src={previewBanner}
                          alt="Banner Preview"
                          style={{
                            width: "256px",
                            height: "160px",
                            objectFit: "cover",
                            borderRadius: "8px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
                          }}
                        />
                      </div>
                    )}

                  </FormGroup>

                  {/* Blog Content Image */}
                  <FormGroup>
                    <Label>Blog Content Image</Label>
                    <Input type="file" accept="image/*" onChange={handleContentImageChange} />
                    {previewContent && (
                      <div className="mt-3">
                        <img src={previewContent} alt="Content Preview" style={{
                            width: "256px",
                            height: "160px",
                            objectFit: "cover",
                            borderRadius: "8px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
                          }} />
                      </div>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <Label>Blog Title</Label>
                    <Controller
                      name="title"
                      control={control}
                      rules={{ required: "Title is required" }}
                      render={({ field }) => <Input {...field} />}
                    />
                    {errors.title && <FormFeedback>{errors.title.message}</FormFeedback>}
                  </FormGroup>

                  <FormGroup>
                    <Label>URL</Label>
                    <Controller
                      name="slug"
                      control={control}
                      rules={{ required: "Slug is required" }}
                      render={({ field }) => <Input {...field} />}
                    />
                    {errors.slug && <FormFeedback>{errors.slug.message}</FormFeedback>}
                  </FormGroup>

                  <FormGroup>
                    <Label>Topic</Label>
                    <Controller
                      name="topic"
                      control={control}
                      render={({ field }) => <Input {...field} />}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Author</Label>
                    <Controller
                      name="author"
                      control={control}
                      render={({ field }) => <Input {...field} />}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Published Date</Label>
                    <Controller
                      name="publishedDate"
                      control={control}
                      render={({ field }) => <DatePicker {...field} selected={field.value} />}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Content</Label>
                    <Controller name="content" control={control} render={({ field }) => <MDEditor {...field} />} />
                  </FormGroup>

                  <Button type="submit" color="primary">Update Blog</Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default EditBlog;
